import { useState } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from "../../components/Message";
import { toast } from 'react-toastify';
import { useUpdateRecipeMutation, useGetRecipeDetailsQuery } from "../../slices/recipesSlice";
import { FaEdit, FaTimes } from "react-icons/fa";
import DashboardNav from "../../components/DashboardNav"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const RecipeEditScreen = () => {
  const{ servername: recipeServerName } = useParams();

  const { data: recipe, refetch, isLoading, error } = useGetRecipeDetailsQuery(recipeServerName);

  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [cooktime, setCooktime] = useState('');
  const [baketemp, setBaketemp] = useState('');
  const [hasSubRecipes, setHasSubRecipes] = useState(false);
  const [ing, setIng] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [methodStep, setMethodStep] = useState('');
  const [method, setMethod] = useState([]);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [video, setVideo] = useState('');
  const [img, setImg] = useState('');
  const [images, setImages] = useState([]);
  const [source, setSource] = useState('');
  const [subRecipe, setSubRecipe] = useState({
    name: '',
    ingredients: [],
    method: []
  });
  const [subRecipes, setSubRecipes] = useState([]);

  const [updateRecipe, { isLoading: updateLoading }] = useUpdateRecipeMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (recipe) {
      setName(recipe.name);
      setCategory(recipe.category);
      setCooktime(recipe.cooktime);
      setBaketemp(recipe.baketemp);
      setIngredients(recipe.ingredients);
      setMethod(recipe.method);
      setNotes(recipe.notes);
      setVideo(recipe.video);
      setImages(recipe.images);
      setSource(recipe.source);
      setHasSubRecipes(recipe.hassubrecipes);
      setSubRecipes(recipe.subrecipes);
    }
  }, [recipe]);

  if (hasSubRecipes) {
    const subRecOptions = document.querySelectorAll('.sub-rec-options');subRecOptions.forEach(option => {
      option.classList.add('active');
      option.classList.remove('hidden');
    });
    document.getElementById('sub-recipes-check').checked = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const updatedRecipe = { 
        _id: recipe._id,
        name, 
        servername: name.toLowerCase().replaceAll(' ', '_'), 
        category, 
        cooktime,
        baketemp,
        hassubrecipes: hasSubRecipes,
        subrecipes: subRecipes,
        ingredients, 
        method, 
        notes, 
        video, 
        images,
        source
      }

      await updateRecipe(updatedRecipe);
      toast.success('Recipe updated');
      refetch();
      navigate('/dashboard/recipes');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

  useEffect(() => {
    setSubRecipe({
      name: subRecipe.name,
      ingredients,
      method
    });
  }, [subRecipe.name, ingredients, method]);

  const addIng = (e) => {
    if (ing === '') {
      return;
    }
    e.preventDefault();
    setIngredients([...ingredients, ing]);
    setIng('');
    document.getElementById('ingredients').focus();
  }

  const addMethod = (e) => {
    if (methodStep === '') {
      return;
    }
    e.preventDefault();
    setMethod([...method, methodStep]);
    setMethodStep('');
    document.getElementById('method').focus();
  }

  const addNote = (e) => {
    if (note === '') {
      return;
    }
    e.preventDefault();
    setNotes([...notes, note]);
    setNote('');
    document.getElementById('notes').focus();
  }

  const addImage = (e) => {
    if (img === '') {
      return;
    }
    e.preventDefault();
    setImages([...images, img]);
    setImg('');
    document.getElementById('images').focus();
  }

  const handleCheck = (e) => {
    const subRecOptions = document.querySelectorAll('.sub-rec-options');
  
    if (e.target.checked) {
      subRecOptions.forEach(option => {
        option.classList.add('active');
        option.classList.remove('hidden');
      })
      setHasSubRecipes(true);
    } else {
      subRecOptions.forEach(option => {
        option.classList.add('hidden');
        option.classList.remove('active');
      })
      setHasSubRecipes(false);
    }
  }

  const subRecipeHandler = () => {
    setSubRecipes([...subRecipes, subRecipe]);
    setIngredients([]);
    setMethod([]);
    setSubRecipe(prevState => (
      {...prevState, name: ''}
    ))
    document.getElementById('sub-recipe-names').focus();
  }

  return (
    <>
      <DashboardNav />
      <h2>Edit Recipe</h2>
      { isLoading ? <Loader /> : 
        error ? <Message variant='danger'>{error}</Message> : (
        <>
          <Form.Group controlId="name" className="my-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}>
            </Form.Control>
          </Form.Group>
          
          <Form.Group controlId="category" className="my-2">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type='text'
              placeholder="Enter category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="cooktime" className="my-2">
            <Form.Label>Cooktime</Form.Label>
            <Form.Control
              type='text'
              placeholder="Cooktime"
              value={cooktime}
              onChange={(e) => setCooktime(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="baketemp" className="my-2">
            <Form.Label>Bake temperature - if you want to preheat oven before step one</Form.Label>
            <Form.Control
              type='text'
              placeholder="Enter preheat temp"
              value={baketemp}
              onChange={(e) => setBaketemp(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form.Check
            type='checkbox'
            id='sub-recipes-check'
            label='This recipe has sub-recipes'
            onClick={handleCheck}
          />

          <div id="sub-recipe-names" className="hidden sub-rec-options">
            <Form.Group controlId="subRecipe" className="my-2">
              <Form.Label>Sub-recipe name</Form.Label>
              <Form.Control
                type='text'
                placeholder="Sub-recipe name"
                value={subRecipe.name}
                onChange={(e) => setSubRecipe(prevState => (
                  {...prevState, name: e.target.value}
                ))}>
              </Form.Control>
            </Form.Group>
          </div>

          <Form onSubmit={addIng}>
            <Form.Group controlId="ingredients" className="my-2">
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                type='text'
                placeholder="Add ingredient"
                value={ing}
                onChange={(e) => setIng(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add ingredient
            </Button>
          </Form>
          <ul>
            {ingredients.map((ingredient, index) => (
              <Row key={index}>
              <Col xs={10}>
                <li >{ingredient}</li>
              </Col>
              <Col xs={1}>
                <Button variant="light" className="btn-sm mx-2">
                    <FaEdit /> 
                </Button>
              </Col>
              <Col xs={1}>
                <Button variant="danger" size="sm" onClick={() => setIngredients(ingredients.slice(0, index).concat(ingredients.slice(index + 1)))}>
                  <FaTimes style={{color: 'white'}}/>
                </Button>
              </Col>
            </Row>
            ))}
          </ul>
          
          <Form onSubmit={addMethod}>
            <Form.Group controlId="method" className="my-2">
              <Form.Label>Method</Form.Label>
              <Form.Control
                type='text'
                placeholder="Add step"
                value={methodStep}
                onChange={(e) => setMethodStep(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add step
            </Button>
          </Form>
          <ul>
            {method.map((step, index) => (
            <Row key={index}>
              <Col xs={10}>
                <li >{step}</li>
              </Col>
              <Col xs={1}>
                <Button variant="light" className="btn-sm mx-2">
                    <FaEdit /> 
                </Button>
              </Col>
              <Col xs={1}>
                <Button variant="danger" size="sm" onClick={() => setMethod(method.slice(0, index).concat(method.slice(index + 1)))}>
                  <FaTimes style={{color: 'white'}}/>
                </Button>
              </Col>
            </Row>
            ))}
          </ul>

          <Button 
            className="sub-rec-options hidden"
            variant="primary"
            type="submit"
            onClick={subRecipeHandler}>
            Add sub-recipe
          </Button>

          <div className="sub-recipe-list sub-rec-options hidden">
            {hasSubRecipes && subRecipes.map((recipe) => (
            <div className="sub-recipe" key={recipe.name}>
              <h5>{recipe.name}</h5>
              <h6>Ingredients</h6>
              <ul className="recipe-list">
                {recipe.ingredients.map((ingredient, index) => (
                  <Row key={index}>
                  <Col xs={6}>
                    <li >{ingredient}</li>
                  </Col>
                  <Col xs={3}>
                    <Button variant="light" className="btn-sm mx-2">
                        <FaEdit /> 
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button variant="danger" size="sm" onClick={() => setIngredients(ingredients.slice(0, index).concat(ingredients.slice(index + 1)))}>
                      <FaTimes style={{color: 'white'}}/>
                    </Button>
                  </Col>
                </Row>
                ))}
              </ul>
              <h6>Method</h6>
              <ol className="recipe-list">
                {recipe.method.map((step, index) => (
                  <Row key={index}>
                  <Col xs={6}>
                    <li >{step}</li>
                  </Col>
                  <Col xs={3}>
                    <Button variant="light" className="btn-sm mx-2">
                        <FaEdit /> 
                    </Button>
                  </Col>
                  <Col xs={3}>
                    <Button variant="danger" size="sm" onClick={() => setMethod(method.slice(0, index).concat(method.slice(index + 1)))}>
                      <FaTimes style={{color: 'white'}}/>
                    </Button>
                  </Col>
                </Row> 
                ))}
              </ol>
            </div>))}
          </div>
          
          <Form onSubmit={addNote}>
            <Form.Group controlId="notes" className="my-2">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type='text'
                placeholder="Add note"
                value={note}
                onChange={(e) => setNote(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add note
            </Button>
          </Form>
          <ul>
            {notes.map((note, index) => (
              <Row key={index}>
              <Col xs={10}>
                <li >{note}</li>
              </Col>
              <Col xs={1}>
                <Button variant="light" className="btn-sm mx-2">
                    <FaEdit /> 
                </Button>
              </Col>
              <Col xs={1}>
                <Button variant="danger" size="sm" onClick={() => setNotes(notes.slice(0, index).concat(notes.slice(index + 1)))}>
                  <FaTimes style={{color: 'white'}}/>
                </Button>
              </Col>
            </Row>
            ))}
          </ul>

          <Form.Group controlId="video" className="my-2">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type='text'
              placeholder="video"
              value={video}
              onChange={(e) => setVideo(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form onSubmit={addImage}>
            <Form.Group controlId="images" className="my-2">
              <Form.Label>Images</Form.Label>
              <Form.Control
                type='text'
                placeholder="Add image"
                value={img}
                onChange={(e) => setImg(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add image
            </Button>
          </Form>
          <ul>
            {images.map((image, index) => (
              <Row key={index}>
                <Col xs={10}>
                  <li >{image}</li>
                </Col>
                <Col xs={1}>
                  <Button variant="light" className="btn-sm mx-2">
                      <FaEdit /> 
                  </Button>
                </Col>
                <Col xs={1}>
                  <Button variant="danger" size="sm" onClick={() => setImages(images.slice(0, index).concat(images.slice(index + 1)))}>
                    <FaTimes style={{color: 'white'}}/>
                  </Button>
                </Col>
              </Row>
            ))}
          </ul>

          <Form.Group controlId="source" className="my-2">
            <Form.Label>Source</Form.Label>
            <Form.Control
              type='text'
              placeholder="source"
              value={source}
              onChange={(e) => setSource(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Button onClick={submitHandler} variant="primary" className="my-2">
            Update Recipe
          </Button>
          { updateLoading && <Loader />}
      </>
      )}
    </>
  )
}

export default RecipeEditScreen
