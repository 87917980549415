import { Image, Table } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useGetJournalEntriesQuery } from '../slices/postsSlice';

function formatDate(createdAt) {
    const date = new Date(createdAt);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

const JournalScreen = () => {
    const { data: journalEntries, isLoading, error } = useGetJournalEntriesQuery();

    return (
      <section id="main">
          {isLoading ? <Loader /> :
          error ? <Message></Message> :
          (
            <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>DATE</th>
                <th>TITLE</th>
                <th>AUTHOR</th>
                <th>BODY</th>
                <th>IMAGES</th>
                <th>VIDEO</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="photo-list">
              {journalEntries.map((journal) => (
                <tr key={journal._id}>
                  <td>{formatDate(journal.createdAt)}</td>
                  <td>{journal.title}</td>
                  <td>{journal.name}</td>
                  <td>{journal.body}</td>
                  <td>{journal.images.length > 0 &&<div className="photos">
                  {journal.images.map((photo) => (
                    <div key={photo._id}>
                      <Image className="photo" src={photo.image} alt={photo.title} fluid />
                    </div>
                  ))}
                </div>}</td>
                  <td>{journal.video && <iframe width="400" height="225" src={journal.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}</td>
                </tr>
              ))}
            </tbody>
          </Table>)}
      </section>
    )
}

export default JournalScreen
