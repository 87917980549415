import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import PrivateRoute from './components/PrivateRoute';
import reportWebVitals from './reportWebVitals';
import HomeScreen from './screens/HomeScreen';
import RecipesScreen from './screens/RecipesScreen';
import RecipeScreen from './screens/RecipeScreen';
import VideosScreen from './screens/VideosScreen';
import GalleryScreen from './screens/GalleryScreen';
import ChamplainScreen from './screens/ChamplainScreen';
import ContactScreen from './screens/ContactScreen';
import LoginScreen from './screens/LoginScreen';
import DashboardScreen from './screens/Dashboard/DashboardScreen';
import ProfileScreen from './screens/ProfileScreen';
import PostListScreen from './screens/Dashboard/PostListScreen';
import PostAddScreen from './screens/Dashboard/PostAddScreen';
import PostEditScreen from './screens/Dashboard/PostEditScreen';
import JournalScreen from './screens/JournalScreen';
import RecipesListScreen from './screens/Dashboard/RecipesListScreen';
import RecipeAddScreen from './screens/Dashboard/RecipeAddScreen';
import RecipeEditScreen from './screens/Dashboard/RecipesEditScreen';
import PhotosListScreen from './screens/Dashboard/PhotosListScreen';
import PhotosAddScreen from './screens/Dashboard/PhotosAddScreen';
import PhotosEditScreen from './screens/Dashboard/PhotosEditScreen';
import VideoListScreen from './screens/Dashboard/VideoListScreen';
import VideoAddScreen from './screens/Dashboard/VideoAddScreen';
import VideoEditScreen from './screens/Dashboard/VideoEditScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/recipes' element={<RecipesScreen />} />
      <Route path='/recipes/:servername' element={<RecipeScreen />} />
      <Route path='/journal' element={<JournalScreen />} />
      <Route path='/videos' element={<VideosScreen />} />
      <Route path='/gallery' element={<GalleryScreen />} />
      <Route path='/champlain' element={<ChamplainScreen />} />
      <Route path='/contact' element={<ContactScreen />} />
      <Route path='/dashboard/10644' element={<LoginScreen />} />

      <Route path='' element={<PrivateRoute />}>
        <Route path='/dashboard' element={<DashboardScreen />} />
        <Route path='/dashboard/profile' element={<ProfileScreen />} />
        <Route path='/dashboard/posts' element={<PostListScreen />} />
        <Route path='/dashboard/posts/addpost' element={<PostAddScreen />} />
        <Route path='/dashboard/posts/edit/:id' element={<PostEditScreen />} />
        <Route path='/dashboard/videos' element={<VideoListScreen/>} />
        <Route path='/dashboard/gallery' element={<PhotosListScreen />} />
        <Route path='/dashboard/gallery/addphoto' element={<PhotosAddScreen />} />
        <Route path='/dashboard/gallery/edit/:id' element={<PhotosEditScreen />} />
        <Route path='/dashboard/recipes' element={<RecipesListScreen />} />
        <Route path='/dashboard/recipes/addrecipe' element={<RecipeAddScreen />} />
        <Route path='/dashboard/recipes/edit/:servername' element={<RecipeEditScreen />} />
        <Route path='/dashboard/videos' element={<VideoListScreen />} />
        <Route path='/dashboard/videos/addvideo' element={<VideoAddScreen />} />
        <Route path='/dashboard/videos/edit/:id' element={<VideoEditScreen />} />
      </Route>

    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);


reportWebVitals();
