import { GALLERY_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const photosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDisplayPhotos: builder.query({
      query: () => ({
        url: GALLERY_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Photos']
    }),
    getAllPhotos: builder.query({
      query: () => ({
        url: `${GALLERY_URL}/all`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Photos']
    }),
    getRecentPhotos: builder.query({
      query: () => ({
        url: `${GALLERY_URL}/recent`,
      }),
      keepUnusedDataFor: 5,
    }),
    getPhotoDetails: builder.query({
      query: (photoId) => ({
        url: `${GALLERY_URL}/${photoId}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Photos'],
    }),
    addPhoto: builder.mutation({
      query: (data) => ({
        url: GALLERY_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Photos'],
    }),
    deletePhoto: builder.mutation({
      query: (photoId) => ({
        url: `${GALLERY_URL}/${photoId}`,
        method: 'DELETE',
      })
    }),
    uploadImage: builder.mutation({
      query: (data) => ({
        url: UPLOAD_URL,
        method: 'POST',
        body: data,
      }),
    }),
    updatePhoto: builder.mutation({
      query: (data) => ({
        url: `${GALLERY_URL}/${data._id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Photos'],
    })
  }),
});

export const { useGetDisplayPhotosQuery, useGetAllPhotosQuery, useGetPhotoDetailsQuery, useAddPhotoMutation, useDeletePhotoMutation, useUpdatePhotoMutation, useUploadImageMutation, useGetRecentPhotosQuery } = photosApiSlice;