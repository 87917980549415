import Loader from "./Loader";
import Message from "./Message";
import { useGetLatestVideosQuery } from "../slices/videosSlice";

const LatestVideos = () => {
  const { data: videos, isLoading, error } = useGetLatestVideosQuery();

  return isLoading ? <Loader /> : error ? <Message variant='danger'>{ error?.data?.message || error.error }</Message> : (
    videos.map((video) => (
    <div id='video-home'>
      <h4>{video.title}</h4>
      <iframe width="400" height="225" src={video.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <p>{video.description}</p>
    </div>
    ))
  )
}

export default LatestVideos