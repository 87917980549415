import { POSTS_URL, JOURNAL_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const postsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: () => ({
        url: POSTS_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Posts']
    }),
    getJournalEntries: builder.query({
      query: () => ({
        url: JOURNAL_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Journal']
    }),
    getPostDetails: builder.query({
      query: (postId) => ({
        url: `${POSTS_URL}/${postId}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Post'],
    }),
    addPost: builder.mutation({
      query: (data) => ({
        url: POSTS_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Post'],
    }),
    deletePost: builder.mutation({
      query: (postId) => ({
        url: `${POSTS_URL}/${postId}`,
        method: 'DELETE',
      })
    }),
    updatePost: builder.mutation({
      query: (data) => ({
        url: `${POSTS_URL}/${data._id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Posts'],
    })
  }),
});

export const { useGetPostsQuery, useGetJournalEntriesQuery, useGetPostDetailsQuery, useAddPostMutation, useDeletePostMutation, useUpdatePostMutation } = postsApiSlice;